/* .sqd-designer */

.sqd-designer-angular{
  height: 500px;
}
.sqd-designer {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
}

.sqd-designer,
.sqd-drag,
.sqd-context-menu {
  font-size: 13px;
  line-height: 1em;
}

.sqd-hidden {
  display: none !important;
}

.sqd-disabled {
  opacity: 0.25;
}

/* .sqd-toolbox */
.sqd-toolbox,
.sqd-toolbox-filter {
  font-size: 11px;
  line-height: 1.2em;
}

.sqd-toolbox {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 20;
  box-sizing: border-box;
  width: 130px;
  -webkit-user-select: none;
  user-select: none;
}

.sqd-toolbox-header {
  position: relative;
  padding: 15px 10px;
  cursor: pointer;
}

.sqd-toolbox-header-title {
  display: block;
  font-size: 1.2em;
  line-height: 1em;
  font-weight: bold;
}

.sqd-toolbox-toggle-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  width: 16px;
  height: 16px;
  margin: -8px 0 0;
}

.sqd-toolbox-header:hover .sqd-toolbox-toggle-icon {
  opacity: 0.6;
}

.sqd-scrollbox {
  position: relative;
  overflow: hidden;
}

.sqd-scrollbox-body {
  position: absolute;
  top: 0;
  left: 0;
}

.sqd-toolbox-filter {
  display: block;
  box-sizing: border-box;
  padding: 6px 8px;
  outline: none;
  width: 110px;
  margin: 0 10px 10px;
  box-sizing: border-box;
}

.sqd-toolbox-group-title {
  text-align: center;
  padding: 5px 0;
  margin: 0 10px 10px;
}

.sqd-toolbox-item {
  position: relative;
  box-sizing: border-box;
  margin: 0 10px 10px;
  width: 110px;
  cursor: move;
}

.sqd-toolbox-item-icon {
  position: absolute;
  top: 50%;
  left: 5px;
  margin-top: -10px;
  width: 20px;
  height: 20px;
}

.sqd-toolbox-item-icon-image {
  width: 100%;
  height: 100%;
}

.sqd-toolbox-item-text {
  position: relative;
  display: block;
  padding: 10px 10px 10px 30px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.sqd-drag {
  position: absolute;
  z-index: 9999999;
  pointer-events: none;
}

/* .sqd-control-bar */
.sqd-control-bar {
  position: absolute;
  bottom: 10px;
  left: 10px;
  z-index: 20;
  padding: 8px 0 8px 8px;
  white-space: nowrap;
}

.sqd-control-bar-button {
  display: inline-block;
  width: 32px;
  height: 32px;
  margin-right: 8px;
  cursor: pointer;
  box-sizing: border-box;
}

.sqd-control-bar-button-icon {
  width: 24px;
  height: 24px;
  margin: 3px 0 0 3px;
}

.sqd-control-bar-button.sqd-disabled .sqd-control-bar-button-icon {
  opacity: 0.2;
}

/* .sqd-smart-editor */
.sqd-smart-editor-toggle {
  position: absolute;
  top: 0;
  z-index: 29;
  width: 36px;
  height: 64px;
  border-bottom-left-radius: 10px;
  cursor: pointer;
}

.sqd-smart-editor-toggle-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 24px;
  height: 24px;
  margin: -12px 0 0 -12px;
}

.sqd-smart-editor-toggle:hover .sqd-smart-editor-toggle-icon {
  opacity: 0.6;
}

.sqd-smart-editor {
  z-index: 30;
}

.sqd-layout-desktop .sqd-smart-editor {
  position: relative;
  width: 300px;
}

.sqd-layout-desktop .sqd-smart-editor-toggle {
  right: 300px;
}

.sqd-layout-desktop .sqd-smart-editor-toggle.sqd-collapsed {
  right: 0;
}

.sqd-layout-mobile .sqd-smart-editor {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 41px;
}

.sqd-layout-mobile .sqd-smart-editor-toggle {
  left: 5px;
}

.sqd-layout-mobile .sqd-smart-editor-toggle.sqd-collapsed {
  left: auto;
  right: 0;
}

/* .sqd-context-menu */
.sqd-context-menu {
  position: absolute;
  z-index: 2000000000;
  overflow: hidden;
  padding: 5px;
}

.sqd-context-menu-group,
.sqd-context-menu-item {
  width: 130px;
  padding: 8px 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.sqd-context-menu-group {
  font-size: 11px;
  line-height: 1em;
}

.sqd-context-menu-item {
  cursor: pointer;
  transition: background 70ms;
}

/* .sqd-workspace */
.sqd-workspace {
  flex: 1;
  position: relative;
  display: block;
  -webkit-user-select: none;
  user-select: none;
}

.sqd-workspace-canvas {
  position: absolute;
  top: 0;
  left: 0;
  cursor: move;
  height: 500px;
}

.sqd-label-text {
  text-anchor: middle;
  dominant-baseline: central;
}

.sqd-placeholder .sqd-placeholder-rect {
  transition: fill 100ms;
}

.sqd-step-task-text {
  text-anchor: left;
  dominant-baseline: central;
}
