:root {
  .mat-mdc-dialog-container .mdc-dialog__surface {
    @apply rounded-lg;
  }

  .mat-mdc-dialog-title {
    @apply pt-3;
  }

  /** Reset default height placeholder */
  .mdc-dialog__title::before {
    content: none;
    height: auto;
  }
}


/* For all screens */
::ng-deep .mat-dialog-container {
  max-width: 100%;
  width: 60%;  /* Default width for larger screens */
}

/* For screens smaller than 768px */
@media (max-width: 768px) {
  ::ng-deep .mat-dialog-container {
    width: 90% !important;  /* For smaller screens, use 90% width */
  }
}

/* For screens smaller than 480px */
@media (max-width: 480px) {
  ::ng-deep .mat-dialog-container {
    width: 95% !important;  /* For very small screens (e.g., mobile phones), use 95% width */
  }
}


