.mdc-notched-outline__notch {
  border-right: none;
}

:root {
  .mdc-text-field--outlined {
    --mdc-outlined-text-field-container-shape: theme("borderRadius.DEFAULT");
  }

  //   /* "outline" appearance */
  .mdc-notched-outline--notched .mdc-notched-outline__notch {
    padding-left: 0;
    border-top: 1px solid;
  }

  //   /* Form field */
  //   .mat-mdc-form-field-flex {
  //     position: relative;
  //     display: flex;
  //     align-items: stretch;
  //   }
  //   /* "outline" appearance */
  //   .mat-mdc-form-field.mat-form-field-appearance-outline {
  //     /* Adjust the top spacing and overflow when mat-label present */
  //     &:not(.mdc-text-field--no-label) {
  //       margin-top: 24px;
  //       overflow: visible;
  //     }

  //     /* Floating label - disable floating action */
  //     .mat-mdc-floating-label {
  //       top: -25px !important;
  //       left: 0 !important;
  //       transform: none !important;
  //       pointer-events: auto;
  //       font-weight: 500;
  //       color: rgba(30, 41, 59, 1) !important;
  //     }

  //     /* Invalid */
  //     &.mat-form-field-invalid {
  //       .mdc-notched-outline__leading,
  //       .mdc-notched-outline__notch,
  //       .mdc-notched-outline__trailing {
  //         border-color: var(--mdc-theme-error, #ef4444) !important;
  //       }
  //     }

  //     /* Focused */
  //     &.mat-focused:not(.mat-form-field-invalid) {
  //       /* Primary */
  //       &.mat-primary {
  //         .mdc-notched-outline__leading,
  //         .mdc-notched-outline__notch,
  //         .mdc-notched-outline__trailing {
  //           border-color: var(--cqa-background-primary) !important;
  //         }
  //       }
  //     }

  //     &:not(.mat-focused):not(.mat-form-field-invalid) {
  //       .mat-mdc-text-field-wrapper {
  //         .mat-mdc-form-field-flex {
  //           .mdc-notched-outline {
  //             .mdc-notched-outline__leading,
  //             .mdc-notched-outline__notch,
  //             .mdc-notched-outline__trailing {
  //               // border-top: 1px solid !important;
  //               border-color: var(--mdc-outlined-text-field-outline-color);
  //             }
  //           }
  //         }
  //       }
  //     }

  //     // /* Remove the extra border on the right side of the notch */
  //     // /* Tailwind's global border setter causes this issue */
  //     // .mat-mdc-text-field-wrapper {
  //     //   .mat-mdc-form-field-flex {
  //     //     .mdc-notched-outline {
  //     //       .mdc-notched-outline__notch {
  //     //       }
  //     //     }
  //     //   }
  //     // }
  //   }

  /* "fill" appearance */
  .mat-mdc-form-field.mat-form-field-appearance-outline {
    /* Disabled */
    &.mat-form-field-disabled {
      opacity: 0.7 !important;
    }

    /* Invalid */
    &.mat-form-field-invalid {
      /* Border color */
      .mat-mdc-text-field-wrapper {
      }

      /* Select */
      .mat-mdc-select {
        /* Placeholder color */
        .mat-mdc-select-placeholder {
        }
      }
    }

    /* Hover */
    &:hover {
      .mat-mdc-form-field-focus-overlay {
        opacity: 0 !important;
      }
    }

    /* Focused */
    &.mat-focused {
      .mat-mdc-form-field-focus-overlay {
        opacity: 0 !important;
      }
    }

    /* Focused and valid fields */
    &.mat-focused:not(.mat-form-field-invalid) {
      /* Border color */
      .mat-mdc-text-field-wrapper {
      }
    }

    /* Remove the default arrow for native select */
    &.mat-mdc-form-field-type-mat-native-select {
      .mat-mdc-form-field-infix {
        select {
          top: auto;
          margin-top: 0;
          margin-bottom: 0;
          padding-top: 0;
          padding-right: 18px;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%2364748B' viewBox='0 0 24 24'%3E%3Cpath d='M7 10l5 5 5-5H7z'/%3E%3C/svg%3E");
          background-repeat: no-repeat;
          background-position: right -7px center;
          background-size: 24px;

          .dark & {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%2397a6ba' viewBox='0 0 24 24'%3E%3Cpath d='M7 10l5 5 5-5H7z'/%3E%3C/svg%3E");
          }
        }

        &:after {
          display: none;
        }
      }
    }

    /* Default style tweaks and enhancements */
    .mat-mdc-text-field-wrapper {
      padding: 0;
      border-width: 0;
      border-style: solid;
      // @apply shadow-sm bg-white border-gray-300 dark:bg-black dark:bg-opacity-5 dark:border-gray-500 #{'!important'};

      /* Adjust the top spacing and overflow when mat-label present */
      &:not(.mdc-text-field--no-label) {
        margin-top: 24px;
        overflow: visible;
      }

      // .mat-mdc-form-field-focus-overlay {
      //   border-radius: 6px;
      // }

      /* Form field */
      .mat-mdc-form-field-flex {
        position: relative;
        display: flex;
        align-items: stretch;
        border-radius: 6px;
        padding: 0 16px;

        .mat-mdc-form-field-icon-prefix,
        .mat-mdc-form-field-text-prefix {
          padding: 0 !important;

          > .mat-icon {
            margin-right: 12px;
            padding: 0 !important;
          }

          > .mat-mdc-icon-button {
            margin: 0 4px 0 -10px;
          }

          > .mat-mdc-select {
            margin-right: 10px;
          }

          > .mat-datepicker-toggle {
            margin-left: -8px;
          }
        }

        .mat-mdc-form-field-icon-suffix,
        .mat-mdc-form-field-text-suffix {
          padding: 0 !important;

          > .mat-icon {
            margin-left: 12px;
            padding: 0 !important;
          }

          > .mat-mdc-icon-button {
            margin: 0 -10px 0 4px;
          }

          > .mat-mdc-select {
            margin-left: 10px;
          }

          > .mat-datepicker-toggle {
            margin-right: -8px;
          }
        }

        .mat-mdc-form-field-icon-prefix,
        .mat-mdc-form-field-text-prefix,
        .mat-mdc-form-field-icon-suffix,
        .mat-mdc-form-field-text-suffix {
          display: inline-flex;
          align-items: center;
          justify-content: center;

          .mat-mdc-icon-button {
            width: 40px;
            min-width: 40px;
            height: 40px;
            min-height: 40px;
          }

          .mat-icon,
          .mat-mdc-icon-button:not([disabled]),
          .mat-mdc-select-value {
          }

          /* Datepicker default icon size */
          .mat-datepicker-toggle-default-icon {
          }

          /* Make mat-select usable as prefix and suffix */
          .mat-mdc-select {
            display: flex;
            align-items: center;

            &:focus {
              .mat-mdc-select-trigger {
                .mat-mdc-select-value {
                }

                .mat-mdc-select-arrow-wrapper {
                  .mat-mdc-select-arrow {
                    border-top-color: var(--fuse-primary) !important;
                  }
                }
              }
            }

            .mat-mdc-select-trigger {
              display: flex;
              align-items: center;

              .mat-mdc-select-value {
                display: flex;
                max-width: none;

                mat-mdc-select-trigger {
                  .mat-icon {
                    margin: 0 !important;
                  }
                }
              }

              .mat-mdc-select-arrow-wrapper {
                display: flex;
                align-items: center;
                transform: none;
                margin-left: 4px;

                .mat-mdc-select-arrow {
                  min-height: 0;
                }
              }
            }
          }
        }

        /* Infix */
        .mdc-notched-outline {
          // position: static;
          display: flex;
          align-items: center;
          // width: 88px;
          min-height: 48px;
          padding: 0;
          border: 0;

          /* Floating label - disable floating action */
          .mat-mdc-floating-label {
            // top: -25px !important;
            // left: 0 !important;
            // width: 100% !important;
            // transform: none !important;
            // pointer-events: auto;
            // font-weight: 500;
            // color: rgba(30, 41, 59, 1) !important;
            top: -25px !important;
            left: 0 !important;
            transform: none !important;
            pointer-events: auto;
            font-weight: 500;
            color: rgba(30, 41, 59, 1) !important;
          }

          /* Textarea */
          textarea.mat-mdc-input-element {
            margin: 12px 0;
            padding: 0 6px 0 0;
          }

          /* Chips */
          .mat-mdc-chip-set {
            width: 100%;
            margin: 0 -8px;
          }
        }
      }

      /* Remove the underline */
      .mdc-line-ripple {
        display: none;
      }
    }

    /* Subscript tweaks */
    .mat-mdc-form-field-subscript-wrapper {
      font-size: 12px;
      font-weight: 500;

      .mat-mdc-form-field-hint-wrapper,
      .mat-mdc-form-field-error-wrapper {
        padding: 0;
      }

      .mat-mdc-form-field-hint {
      }
    }

    /* Adds better alignment for textarea inputs */
    &:has(textarea.mat-mdc-input-element) {
      .mat-mdc-text-field-wrapper {
        .mat-mdc-form-field-flex {
          .mat-mdc-form-field-icon-prefix,
          .mat-mdc-form-field-text-prefix,
          .mat-mdc-form-field-icon-suffix,
          .mat-mdc-form-field-text-suffix {
            align-self: flex-start;
            padding-top: 14px !important;
          }
        }
      }
    }
  }
}
