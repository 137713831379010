:root {
  .mat-mdc-button,
  .mat-mdc-outlined-button,
  .mat-mdc-unelevated-button,
  .mat-mdc-raised-button {
    @apply rounded-button min-w-[6rem];
  }

  .mat-button-toggle-group:not(.mat-button-toggle-vertical) {
    @apply rounded-button;

    .mat-button-toggle:first-child .mat-button-toggle-label-content {
      @apply pl-4;
    }

    .mat-button-toggle:last-child .mat-button-toggle-label-content {
      @apply pr-4;
    }
  }

  .mat-button-toggle-button {
    @apply font-medium;
  }

  .mat-mdc-icon-button {
    .mat-icon {
      @apply inline-flex items-center justify-center;
    }
  }
}

// new design button changes
:root {
  .mat-mdc-raised-button.mat-primary,
  .mat-mdc-outlined-button.mat-primary,
  .mat-mdc-unelevated-button.mat-primary,
  .mat-mdc-raised-button.mat-primary,
  .mat-mdc-mini-fab.mat-primary {
    &:not([disabled="true"]) {
      background-color: var(--cqa-background-primary);
      color: white;
    }
  }
  .mat-mdc-mini-fab {
    box-shadow: none;
  }

  .mat-mdc-outlined-button.mat-unthemed {
    background-color: var(--cqa-background-primary);
    color: white;
  }
  .mat-mdc-outlined-button.mat-unthemed.cancel {
    background-color: transparent;
    color: black;
  }

  mat-paginator.mat-mdc-paginator {
    background-color: transparent;
    .mat-mdc-paginator-container {
      .mat-mdc-paginator-range-actions {
        gap: 5px;
      }
    }
    .mat-mdc-icon-button.mat-mdc-button-base {
      width: 38px;
      height: 38px;
      padding: 8px;
      border-radius: 50%;

      /* Not disabled */
      &:not([disabled="true"]) {
        background-color: var(--cqa-background-primary);
      }

      /* Disabled */
      &[disabled="true"] {
        background-color: #17aeff7e;
      }
      .mat-icon {
        @apply text-white;
      }

      .mat-mdc-paginator-icon {
        fill: white;
      }
    }
  }

  .mat-mdc-icon-button.mat-primary {
    .mat-badge-content {
      background-color: var(--cqa-background-primary);
    }
  }
}
