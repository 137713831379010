@import "~simplebar/dist/simplebar.css";
@import "~highlight.js/styles/androidstudio.css";
// Vex Core
@import "./@vex/styles/core";
@import "./@vex/styles/tailwind.scss";
/*
  You can override any CSS Variable or style here
  Example:

  :root {
   --vex-sidenav-background: #333333;
  }

  All possible variables can be found in @vex/styles/core or by simply inspecting the element you want to change
  in Chrome/Firefox DevTools
*/

/**
  Uncomment the below code and adjust the values to fit your colors, the application will automatically adjust
 */

:root {
  --vex-sidenav-background: #fff;
}
html {
  --vex-color-primary: blue;
  --vex-color-primary-contrast: white;

  // --vex-color-accent: yellow;
  // --vex-color-accent-contrast: black;

  // --vex-color-warn: yellow;
  // --vex-color-warn-contrast: black;
}

.iti {
  display: block !important;
}

.iti .dropdown-menu.country-dropdown {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-color: #c7cace;
  margin-top: -1px;
}

.iti .iti__country-list {
  box-shadow: none;
  font-size: 14px;
  margin-left: 0;
  width: 244px;
  max-height: 170px;
}

.iti__flag-container.open + input {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.iti .search-container input {
  font-size: 14px;
  border-color: #c7cace;
  border-radius: 0;
  padding: 5px 10px;
}

.iti .search-container input:focus {
  outline: none;
}

@media screen and (max-width: 479px) {
  .iti .iti__country-list {
    width: 88.3vw;
  }
}

ngx-intl-tel-input input {
  height: 48px;
  padding: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: #90959b;
  border-radius: 8px;
  font-size: 15px;
  padding-left: 44px;
  width: 100%;
  color: var(--mdc-outlined-text-field-input-text-color);
}

ngx-intl-tel-input.ng-invalid.ng-touched input {
  border: 1px solid #ef4444;
}

// ngx-intl-tel-input input:hover {
//   border: 2px solid black;
// }

ngx-intl-tel-input input:focus {
  outline: none !important;
  border-color: var(--mdc-outlined-text-field-caret-color);
  box-shadow: 0 0 0 0 #000;
}

ngx-intl-tel-input input::-webkit-input-placeholder {
  color: #bac2c7;
}

ngx-intl-tel-input input:-ms-input-placeholder {
  color: #bac2c7;
}

ngx-intl-tel-input input::-ms-input-placeholder {
  color: #bac2c7;
}

ngx-intl-tel-input input::placeholder {
  color: #bac2c7;
}

ngx-intl-tel-input input[disabled] {
  background-color: #e5eaf1;
}

// file dropzone
$sea: #007bff;
$dropzone-border: #c6ccd6;
$dropzone-background: #f9f9fb;
$file-text-color: #8c96a8;
$file-icon-border: lighten(#8c96a8, 15);

.dropzone {
  border: 0.0625rem solid $dropzone-border;
  border-radius: 0.5rem;
  background-color: $dropzone-background;
  display: block;

  .dropzone-container {
    padding: 2rem 0;
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $file-text-color;
    z-index: 20;

    .dropzone-title {
      padding-top: 1.5rem;
    }

    .browse {
      text-decoration: underline;
      color: $sea;
    }
  }

  .file-input {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
    cursor: pointer;
  }
}

// parent should have z-index
.file-icon {
  /* Need position to allow stacking of pseudo-elements */
  position: relative;
  width: 4rem;
  height: 5.25rem;
  /* Padding for demo purposes */
  padding: 0.625rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 2rem;
  color: $sea;

  &,
  &:before,
  &:after {
    background-color: #ffffff;
    border-radius: 0.25rem;
    border: 0.125rem solid $file-icon-border;
  }

  &:before,
  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    border: 0.125rem solid lighten($file-icon-border, 7);
  }

  /* Second sheet of paper */
  &:before {
    left: -0.625rem;
    top: 0.5rem;
    z-index: -1;
  }

  &:after {
    top: -0.25rem;
    right: -0.25rem;
    left: auto;
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 0.25rem;
    border-width: 0.625rem;
    border-style: solid;
    border-color: $dropzone-background $dropzone-background $file-icon-border
      $file-icon-border;
  }
}
.custom-label {
  font-weight: 400;
  color: rgba(30, 41, 59, 1) !important;
}
// end

.primary-btn {
  color: #f0fff3 !important;
  background-color: #092040 !important;
}
.secondary-btn {
  color: #f0fff3 !important;
  background-color: #17aeff !important;
}

.more-info-btn {
  color: #f0fff3 !important;
  background-color: #5d7fc8 !important;
}

.swal2-confirm {
  background-color: var(--cqa-background-primary) !important;
}

// loader animation
@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
}
.loader div {
  width: 15px;
  height: 15px;
  margin: 3px;
  background-color: #3498db;
  border-radius: 50%;
  animation: bounce 1s infinite;
}
.loader div:nth-child(1) {
  animation-delay: 0s;
}
.loader div:nth-child(2) {
  animation-delay: 0.2s;
}
.loader div:nth-child(3) {
  animation-delay: 0.4s;
}


/* If the scrollbar color should apply globally, use this */
::-webkit-scrollbar {
  // width: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #C1C1C1; /* Scrollbar thumb color */
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #999A9B; /* Scrollbar hover color */
}