    /* Subscript tweaks */
    .mat-mdc-form-field-subscript-wrapper {
        font-size: 12px;
        font-weight: 500;

        .mat-mdc-form-field-hint-wrapper,
        .mat-mdc-form-field-error-wrapper {
            padding: 0;
        }
    }

    .mdc-dialog__title{
        padding:12px !important;
    }

        .mdc-dialog__actions{
        padding: 12px !important;
        }


//         .mat-drawer{
// width: 22.5rem !important;
//         }  